const TITLE = 'PRINTEMPS.COM';
const SUBTITLE = 'You are unique. So is our style.';
const BANNER_LINK = 'https://www.printemps.com';


export const Header = () => {
    return (
        <>
            <div className="header-content" style={{marginBottom:60}}>
                <div
                    className="logo"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'fixed',
                        background: 'white',
                        width: '100%',
                        zIndex: 500,
                        boxShadow: 'rgb(0 0 0 / 8%) 2px 0px 4px'
                    }}
                >
                    <div
                        className="container"
                        style={{ display: 'flex', alignItems: 'center', maxWidth: '100%', padding: '1rem' }}
                    >
                        <div
                            className={'col-12'}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <a
                                href={BANNER_LINK}
                                className="logo--title m-auto pb-0"
                                style={{ display: 'inline-block' }}
                            >
                                <h1 className="mb-0">
                                    <p className="logo--title-pcom mb-1 mt-0">{TITLE}</p>
                                    <p className="logo--subtitle pb-2">{SUBTITLE}</p>
                                </h1>
                            </a>
                            <a href={BANNER_LINK} className="logo--title-sticky m-auto">
                                <h1>
                                    <p className="logo--title-pcom mb-1">{TITLE}</p>
                                    <p className="logo--subtitle pb-1 pb-md-0 color--black">{SUBTITLE}</p>
                                </h1>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;