import styled from "styled-components";

export const ErrorImageOverlay = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorImageText = styled.h2`
  font-size: 22px;
  text-align: center;
  width: 90vw;
  color: black;
  margin-top: 20px;
  max-width: 1200px;
`;
