import React from "react";
import { strings } from "../../res/i18n/strings";
import {
  ErrorImageText,
  ErrorImageOverlay,
} from "./no-found.styles";

export default function NotFoundPage() {
  const { notFound: { broken }, contact:{mail, phone, phoneNumber, mailAdress} } = strings;
  return (
    <ErrorImageOverlay>
      <ErrorImageText>{broken}</ErrorImageText>
      <ErrorImageText><span style={{ fontWeight:'bold'}}>{mail}</span> : <a href={`mailto:${mailAdress}`}>{mailAdress}</a> </ErrorImageText>
      <ErrorImageText><span style={{ fontWeight:'bold'}}>{phone}</span> : {phoneNumber}</ErrorImageText>
      
    </ErrorImageOverlay>
  )
}
