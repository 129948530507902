import React, { Suspense, lazy } from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Spinner from './ui/spinner/spinner.component';
import NotFoundPage from './ui/not-found/not-found.pages';
import { routes } from './navigation/routes';
import { Header } from './ui';

const VisaPersonalShopperPage = lazy(() => import("./pages/personal-shopper/personal-shopper.page"));


function App() {
  const { Partner } = routes;
  return (
    <Suspense fallback={<Spinner />}>
      <Header />
      <Routes>
        <Route path={Partner} element={<VisaPersonalShopperPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      
    </Suspense>
  );
}

export default App;
