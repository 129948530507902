const fr = {
    asideNav: {
        magasins      : 'magasins',
        magasinsLink  : 'https://www.printemps.com/fr/fr/magasins',
        seConnecter: 'se connecter',
        seConnecterLink: 'https://www.printemps.com/fr/fr/connexion',
        creerUnCompteLink : 'https://www.printemps.com/fr/fr/creation-compte',
        creerUnCompte : 'créer un compte'
    },
    notFound: {
        broken : "Vous êtes connecté à une mauvaise adresse. Si vous souhaitez prendre rendez-vous avec un Personal Shopper au Printemps, veuillez contacter notre service clientèle."
    },
    contact: {
        mail: 'Email',
        phone: 'le numéro de tél :',
        mailAdress: 'customer-service@printemps.com',
        phoneNumber:'+442076602562'
    }   
};



const en = {
    asideNav: {
        magasins      : 'stores',
        magasinsLink  : 'https://www.printemps.com/uk/en/stores',
        seConnecter: 'sign in',
        seConnecterLink: 'https://www.printemps.com/uk/en/signin',
        creerUnCompteLink : 'https://www.printemps.com/uk/en/create-account',
        creerUnCompte : 'Create an account'
    },
    notFound: {
        broken : "You are visiting a wrong address. If you would like to make an appointment with a Personal Shopper at Printemps, please contact our customer service."
    },
    contact: {
        mail: 'Email',
        phone: 'Phone',
        mailAdress: 'customer-service@printemps.com',
        phoneNumber:'+442076602562'
    }   
};

const i18n = {
    fr,
    en
};

export const strings = i18n.en;
